// 获取屏幕宽度：window.screen.width * window.devicePixelRatio
// 获取屏幕高度：window.screen.height * window.devicePixelRatio
// 答题卡纸张；类型
export const paperTypeList = [
  {
    value: 1,
    label: "A4",
  },
  {
    value: 2,
    label: "A3",
  },
  {
    value: 3,
    label: "B4",
  },
];

// 选择题类型
export const choiceQuestionTypeList = [
  {
    label: "单选题",
    value: 1,
  },
  {
    label: "多选题",
    value: 2,
  },
  // {
  //   label: "判断题",
  //   value: 3,
  // },
];

// 新建答题卡类型
export const createMarkTypeList = [
  {
    value: 0,
    label: "网上阅卷",
  },
  {
    value: 1,
    label: "先阅后扫",
  },
];

// 装订线的josn 单位mm
export const gutter = {
  nameStr: "装订线",
  width: 36,
  // 条形码区域
  gutterBarCode: {
    left: -6,
    top: 50,
    width: 53,
    height: 25,
  },
  // 学生信息
  gutterInfo: {
    left: -55,
    top: 180,
    width: 166,
    height: 10,
  },
};
const optionArr = [
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
];

export const optionArray = () => {
  let InArr = [
    {
      value: 1,
      number: 2,
      label: "T-F",
    },
  ];
  const arr = optionArr.map((item, index) => {
    return {
      value: index + 2,
      number: index + 2,
      label: "A-" + item,
    };
  });

  InArr = InArr.concat(arr);
  // console.log(InArr);
  return InArr;
};

// 填空题每行试题数量
export const completionRowNum = [{ value: 1 }, { value: 2 }, { value: 3 }];

// 填空题每题小空数量
export const completionItemNum = [
  { value: 1 },
  { value: 2 },
  { value: 3 },
  { value: 4 },
];
