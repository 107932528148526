<template>
  <div class="sheetList">
    <div class="search-box">
      <div class="search-left">
        答题卡类型：
        <el-select
          v-model="search.type"
          placeholder="请选择"
          @change="getSheetList"
        >
          <el-option
            v-for="item in sheetTypeList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        科目：
        <el-select
          v-model="search.subjectId"
          placeholder="请选择"
          clearable
          @change="getSheetList"
        >
          <el-option
            v-for="item in subjectOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        学段：
        <el-select
          v-model="search.levelId"
          placeholder="请选择"
          clearable
          @change="getSheetList"
        >
          <el-option
            v-for="item in levelOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        类型：
        <el-select
          v-model="search.markType"
          placeholder="请选择"
          clearable
          @change="getSheetList"
        >
          <el-option
            v-for="item in createMarkTypeList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        入学年份：
        <el-select
          v-model="search.year"
          placeholder="请选择"
          clearable
          @change="getSheetList"
        >
          <el-option
            v-for="item in yearList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <!-- <el-date-picker
          v-model="search.year"
          style="width: 100px"
          type="year"
          placeholder=""
        >
        </el-date-picker> -->
        <el-input
          v-model="search.name"
          placeholder="请输入答题卡名称"
          @keyup.enter.native="getSheetList"
        >
          <i
            slot="suffix"
            class="el-icon-search el-input__icon"
            @click="getSheetList"
          >
          </i
        ></el-input>
      </div>
    </div>
    <div class="sheet-list">
      <div class="btn-list">
        <el-button type="primary" @click="createSheet()">
          <img src="@/assets/uExam/icon_add_w.png" alt="" />
          <img class="on" src="@/assets/uExam/icon_add_w.png" alt="" />
          创建答题卡模版
        </el-button>
        <el-button type="primary" @click="createThreeSheet()">
          <img src="@/assets/uExam/icon_cut.png" alt="" />
          <img class="on" src="@/assets/uExam/icon_cut.png" alt="" />
          裁切第三方卡
        </el-button>
        <el-button type="primary" @click="batchAmendingVisible = true">
          <img src="@/assets/uExam/icon_import.png" alt="" />
          <img class="on" src="@/assets/uExam/icon_import.png" alt="" />
          导入模板
        </el-button>
        <el-button type="primary" :loading="exBtnLoading" @click="exportFile()">
          <img src="@/assets/uExam/icon_export.png" alt="" />
          <img class="on" src="@/assets/uExam/icon_export.png" alt="" />
          批量导出模板
        </el-button>
        <el-button
          type="primary"
          :loading="exportFileLoading"
          @click="downFile()"
        >
          <img src="@/assets/uExam/icon_push.png" alt="" />
          <img class="on" src="@/assets/uExam/icon_push.png" alt="" />
          批量下载模板
        </el-button>
      </div>
      <el-table
        ref="multipleTable"
        v-loading="listLoading"
        :data="sheetDataList"
        border
        tooltip-effect="dark"
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" align="center">
          <template slot-scope="{ row }">
            <el-checkbox-group v-model="multipleSelection">
              <el-checkbox :label="row.uuid" :disabled="!row.totalScore">
                {{ row.aa }}
              </el-checkbox>
            </el-checkbox-group>
          </template>
        </el-table-column>

        <el-table-column
          header-align="center"
          prop="name"
          label="答题卡模版"
          min-width="340"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <!-- <template v-if="scope.row.type == 0"> -->
            <el-button type="text" @click="toImg(scope.row)">
              {{ scope.row.name }}
            </el-button>
            <!-- </template> -->
            <!-- <template v-else> {{ scope.row.name }}</template> -->
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="name"
          label="阅卷类型"
          min-width="70"
        >
          <!-- markType -->
          <template slot-scope="scope">
            <!--  -->
            <span :style="{ color: scope.row.markType == 1 ? '#2474ED' : '' }">
              {{ scope.row.markType == 1 ? "先阅后扫" : "网上阅卷" }}
            </span>
            <!-- {{ scope.row.markType == 1 ? "先阅后扫" : "网上阅卷" }} -->
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="name"
          label="答题卡类型"
          min-width="80"
        >
          <!-- markType -->
          <!--  -->
          <template slot-scope="scope">
            <span :style="{ color: scope.row.type == 1 ? '#2474ED' : '' }">
              {{ scope.row.type == 1 ? "网页裁切" : "网页制卡" }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          prop="totalScore"
          label="总分"
          align="center"
          min-width="70"
        >
          <template slot-scope="scope">
            {{ scope.row.totalScore }}

            <template v-if="!scope.row.totalScore">
              <el-tooltip
                class="item"
                effect="dark"
                content="原因：您还没有添加题目，请点击“编辑”进行答题卡的内容设计"
                placement="right"
              >
                <el-button type="text" icon="el-icon-warning-outline">
                  不可用
                </el-button>
              </el-tooltip>
            </template>
          </template>
        </el-table-column>
        <el-table-column
          prop="totalScore"
          label="纸张"
          align="center"
          min-width="80"
        >
          <template slot-scope="scope">
            {{ scope.row.paperType | setName(paperTypeList) }}
            <template v-if="scope.row.columnCount && scope.row.type == 0">
              {{ scope.row.columnCount | capitalNumber(1) }}栏
            </template>
            <!-- <template v-else>标注卡</template> -->
          </template>
        </el-table-column>
        <el-table-column
          prop="maintenanceTime"
          label="维护时间"
          align="center"
          min-width="120"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column label="科目" align="center" min-width="70">
          <template slot-scope="scope">
            {{ scope.row.subjectId | setName(subjectOptions) }}
          </template>
        </el-table-column>
        <el-table-column label="学年" prop="year" align="center" min-width="50">
          <template slot-scope="scope">
            {{ scope.row.year || "-" }}
          </template>
        </el-table-column>
        <el-table-column
          prop="createUser"
          label="创建人"
          align="center"
          min-width="80"
        >
        </el-table-column>
        <el-table-column
          prop="createUser"
          label="编辑模板"
          align="center"
          min-width="80"
        >
          <template slot-scope="scope">
            <template v-if="scope.row.examPaperId">应用到考试</template>
            <template v-else>
              <!-- <template v-if="scope.row.totalScore"> -->
              <!-- <template v-if="scope.row.type == 0"> -->
              <el-button type="text" class="succ" @click="showEdit(scope.row)">
                编辑
              </el-button>
              <!-- </template> -->
              <!-- <template v-else>-</template> -->

              <!-- </template>
              <template v-else>-</template> -->
            </template>
          </template>
        </el-table-column>
        <el-table-column
          prop="createUser"
          label="修改模板"
          align="center"
          min-width="80"
        >
          <template slot-scope="scope">
            <template v-if="scope.row.examPaperId">应用到考试</template>
            <template v-else>
              <!-- <template v-if="scope.row.type == 0"> -->
              <el-button type="text" @click="edit(scope.row)">修改</el-button>
              <!-- </template>
              <template v-else>-</template> -->
            </template>
          </template>
        </el-table-column>
        <el-table-column
          prop="createUser"
          label="操作"
          align="center"
          min-width="150"
        >
          <template slot-scope="scope">
            <el-button
              v-if="!scope.row.examPaperId"
              type="text"
              class="err"
              @click="delExampapersheet(scope.row)"
            >
              删除
            </el-button>
            <template v-if="scope.row.totalScore">
              <el-button type="text" @click="exampapersheetDuplicate(scope.row)"
                >复制</el-button
              >
              <el-button
                type="text"
                :loading="exportLoading"
                @click="exportSheet(scope.row)"
              >
                导出
              </el-button>
            </template>
            <el-button
              v-if="showUp(scope.row)"
              type="text"
              :loading="scope.row.virtualBtn"
              @click="getVirtual(scope.row)"
            >
              匹配试题
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page="pageIndex"
        :page-sizes="[10, 20, 50, 100, 200, 500, 1000]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
    <el-dialog title="修改答题卡" :visible.sync="dialogVisible" width="30%">
      <div class="item-list">
        <span> 模板名称：</span>

        <el-input
          v-model="name"
          placeholder="请输入模板名称"
          maxlength="50"
          show-word-limit
        >
        </el-input>
      </div>
      <div class="item-list">
        <span> 入学年份：</span>

        <el-date-picker
          v-model="year"
          format="yyyy"
          value-format="yyyy"
          type="year"
          style="width: 272px"
          placeholder="无入学年份"
        ></el-date-picker>
      </div>
      <div class="item-list">
        <span style="display: inline-block; width: 75px; text-align: right">
          学段：</span
        >

        <el-select
          v-model="levelId"
          style="width: 250px"
          placeholder="学段"
          clearable
        >
          <el-option
            v-for="item in levelOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="item-list">
        <span> 所属科目：</span>

        <el-radio-group v-model="subjectId">
          <el-radio
            v-for="(v, i) in subjectOptions"
            :key="i"
            :label="v.value"
            >{{ v.label }}</el-radio
          >
        </el-radio-group>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" :loading="btnLoading" @click="editList()">
          确 定
        </el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="答题卡导入"
      :visible.sync="batchAmendingVisible"
      width="624px"
      class="self-amending-form"
    >
      <!-- <div class="upload-tips">
        确定导入答题卡至 {{ examInformation.examName }}-{{
          indexSubject.label
        }}?
      </div> -->
      <el-upload
        class="upload-file"
        :drag="true"
        :action="fileURL"
        :headers="uploadHeaders"
        accept=".json"
        :show-file-list="false"
        :before-upload="handleImportBefore"
        :on-success="batchModifyOnSuccess"
        :on-error="batchModifyOnError"
        :on-progress="batchModifyOnProgress"
      >
        <div class="el-upload__text">将文件拖到此处，或点击上传</div>
      </el-upload>
      <div>只能导入由系统导出的 .json 文件</div>
      <!-- <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="dataFormSubmit()">确 定</el-button>
        </span> -->
    </el-dialog>
    <copysheet ref="copysheet"></copysheet>
  </div>
</template>

<script>
import { getSubjectList, fileDownloadByUrl, getYear } from "@/core/util/util";
import { paperTypeList } from "@/core/util/sheetPaper";
import {
  exampapersheetPage,
  delExampapersheet,
  exampapersheetExport,
  exampapersheetDownload,
  // exampapersheetDuplicate,
  putExamPapersheet,
  virtual,
} from "@/core/api/exam/sheet";
import { levelOptions } from "@/core/util/constdata";
import { createMarkTypeList } from "@/core/util/sheetPaper";
import { getStore } from "@/core/util/store";
import copysheet from "./components/copysheet.vue";
// 答题卡类型列表
const sheetTypeList = [
  {
    value: "",
    label: "全部类型",
  },
  {
    value: 0,
    label: "网页制卡",
  },
  {
    value: 1,
    label: "裁切卡",
  },
  {
    value: 2,
    label: "题库卡",
  },
];
export default {
  name: "SheetList",
  components: { copysheet },
  data() {
    return {
      levelOptions: levelOptions(),
      batchAmendingVisible: false,
      listLoading: false,
      exportLoading: false,
      // exportBtnLoading: false,
      fileURL: "/exam/exampapersheet/import",
      wordData: {},
      uploadHeaders: {
        Authorization: "Bearer " + getStore({ name: "access_token" }),
        "SCHOOL-ID": getStore({ name: "school_id" }),
      },
      yearList: getYear(),
      name: "",
      year: "",
      levelId: "",
      subjectId: "",
      dialogVisible: false,
      subjectOptions: [],
      multipleSelection: [],
      sheetTypeList: sheetTypeList,
      createMarkTypeList: createMarkTypeList,
      sheetDataList: [],
      pageIndex: 1,
      pageSize: 50,
      total: 0,
      paperTypeList: paperTypeList,
      btnLoading: false,
      exBtnLoading: false,
      exportFileLoading: false,
      search: {
        type: "",
        subjectId: "",
        year: "",
        name: "",
        markType: "",
        levelId: "",
      },
    };
  },
  created() {
    this.subjectOptions = getSubjectList();
    this.exampapersheetPage();
    this.userInfo = getStore({ name: "userInfo" });
  },
  methods: {
    showUp(item) {
      let type = true;
      if (item.examPaperId) {
        type = false;
      }
      if (this.userInfo.schoolVersion == 1) {
        type = false;
      }
      return type;
    },
    // 答题卡上传 start
    handleImportFileRemove(fileList) {
      fileList.splice(0, 1);
    },
    batchModifyOnError(err, file, fileList) {
      this.$message({
        showClose: true,
        type: "warning",
        message: "服务器内部错误！",
        duration: 1500,
      });
      this.loading = false;
      this.handleImportFileRemove(fileList);
    },
    batchModifyOnProgress() {
      this.batchAmendingVisible = false;
      this.loading = true;
    },
    handleImportBefore(file) {
      if (!file.name.endsWith(".json")) {
        this.$message.error("只能上传系统导出的 json 文件！");
        return false;
      }
      let is10M = file.size / 1024 / 1024 <= 10;
      if (!is10M) {
        this.$message.error("文件大小不能超过 10 MB！");
        return false;
      }
      // const _vm = this;
      // _vm.wordData.examPaperId = _vm.indexSubject.val;
      return true;
    },
    handleImportOnProgress() {
      this.batchAmendingVisible = false;
    },
    batchModifyOnSuccess(response) {
      // console.log(response);
      if (response.code === 0) {
        this.batchAmendingVisible = false;
        this.$message({
          showClose: true,
          type: "success",
          message: "导入成功",
          duration: 2000,
        });
        this.exampapersheetPage();
      } else {
        let responseMsg = response.msg || "服务器内部错误！";

        this.$message({
          showClose: true,
          type: "warning",
          message: responseMsg,
          duration: 20000,
        });
      }
    },
    // 答题卡上传 end
    edit(item) {
      this.indexItem = item;
      this.subjectId = this.indexItem.subjectId;
      this.year = this.indexItem.year.toString();
      this.name = this.indexItem.name;
      if (this.indexItem.levelId) {
        this.levelId = this.indexItem.levelId.toString();
      } else {
        this.levelId = "";
      }

      this.dialogVisible = true;
    },
    editList() {
      let data = {
        uuid: this.indexItem.uuid,
        subjectId: this.subjectId,
        year: this.year,
        levelId: this.levelId,
        name: this.name,
      };
      this.btnLoading = true;
      putExamPapersheet(data)
        .then(() => {
          this.$message({
            type: "success",
            message: "修改成功!",
          });
          this.dialogVisible = false;
          this.btnLoading = false;
          this.exampapersheetPage();
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
    // 获取examId
    getVirtual(item) {
      item.virtualBtn = true;
      let data = {
        sheetUUID: item.uuid,
      };
      virtual(data)
        .then((res) => {
          this.$router.push({
            path: "/exam/exampaper",
            query: {
              id: res.data.data,
              subId: item.subjectId,
              ifVirtual: true,
            },
          });
          item.virtualBtn = false;
        })
        .catch(() => {
          item.virtualBtn = false;
        });
    },
    exampapersheetExport(data, item) {
      exampapersheetExport(data)
        .then((res) => {
          let name = item.name;
          fileDownloadByUrl(res.data.data.url, name);
          this.exportLoading = false;
          this.exBtnLoading = false;
        })
        .catch(() => {
          this.exportLoading = false;
          this.exBtnLoading = false;
        });
    },
    exampapersheetDownload(data, item) {
      exampapersheetDownload(data)
        .then((res) => {
          let name = item.name;
          fileDownloadByUrl(res.data.data.url, name);

          this.exportFileLoading = false;
        })
        .catch(() => {
          this.exportFileLoading = false;
        });
    },
    exportSheet(item) {
      let data = {
        sheetUuids: item.uuid,
      };
      this.exportLoading = true;
      this.exampapersheetExport(data, item);
    },
    delExampapersheet(item) {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          delExampapersheet(item.uuid).then(() => {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.exampapersheetPage();
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    exampapersheetDuplicate(item) {
      this.$refs.copysheet.init(item);
      // this.$confirm("复制答题卡, 是否继续?", "提示", {
      //   confirmButtonText: "确定",
      //   cancelButtonText: "取消",
      //   type: "warning",
      // })
      //   .then(() => {
      //     let data = {
      //       sheetUuid: item.uuid,
      //     };
      //     exampapersheetDuplicate(data).then(() => {
      //       this.$message({
      //         type: "success",
      //         message: "复制成功!",
      //       });
      //       this.exampapersheetPage();
      //     });
      //   })
      //   .catch(() => {
      //     this.$message({
      //       type: "info",
      //       message: "已取消",
      //     });
      //   });
    },
    createSheet() {
      this.$router.push({
        name: "CreateSheet",
      });
    },
    createThreeSheet() {
      this.$router.push({
        name: "CreateThirdSheet",
        query: {
          from: "SheetList",
        },
      });
    },
    exportFile() {
      let date = new Date().getDate();
      let getMonth = new Date().getMonth();
      let getHours = new Date().getHours();
      let getMinutes = new Date().getMinutes();

      let name =
        getMonth + 1 + "月" + date + "日" + getHours + "时" + getMinutes + "分";
      if (this.multipleSelection.length == 0) {
        this.$message({
          showClose: true,
          message: "请选择需要导出的答题卡",
          type: "warning",
        });
        return;
      }
      let uuids = this.multipleSelection;
      let data = {
        sheetUuids: uuids.join(","),
      };

      let item = {
        name: name + " 答题卡组",
      };
      this.exBtnLoading = true;
      this.exampapersheetExport(data, item);
    },
    downFile() {
      let date = new Date().getDate();
      let getMonth = new Date().getMonth();
      let getHours = new Date().getHours();
      let getMinutes = new Date().getMinutes();

      let name =
        getMonth + 1 + "月" + date + "日" + getHours + "时" + getMinutes + "分";
      if (this.multipleSelection.length == 0) {
        this.$message({
          showClose: true,
          message: "请选择需要下载的答题卡",
          type: "warning",
        });
        return;
      }
      let uuids = this.multipleSelection;
      let data = {
        sheetUuids: uuids.join(","),
      };

      let item = {
        name: name + " 答题卡组",
      };
      this.exportFileLoading = true;
      this.exampapersheetDownload(data, item);
    },
    showEdit(val) {
      let data = {
        sheetId: val.uuid,
      };
      if (val.type != 0) {
        data.examId = "-1";
      }
      data.from = "SheetList";
      const routeData = this.$router.resolve({
        name: val.type == 0 ? "SheetEdit" : "ThirdPartyAnswerSheet",
        query: data,
      });
      window.open(routeData.href, "_blank");
      // ?
    },
    toImg(val) {
      let data = {
        sheetId: val.uuid,
      };
      const routeData = this.$router.resolve({
        name: "showSheetImg",
        query: data,
      });
      window.open(routeData.href, "_blank");
      // this.$router.push({
      //   name: "showSheetImg",
      //   query: data,
      // });
    },
    delSheet() {},
    // 考生上传end
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.pageSize = val;
      this.exampapersheetPage();
    },
    handleCurrentChange(val) {
      this.pageIndex = val;
      this.exampapersheetPage();
      // console.log(`当前页: ${val}`);
    },
    exampapersheetPage() {
      this.sheetDataList = [];
      this.loading = true;
      this.listLoading = true;

      exampapersheetPage(this.search, {
        current: this.pageIndex,
        size: this.pageSize,
      })
        .then((res) => {
          this.sheetDataList = res.data.data.records.map((item) => {
            item.virtualBtn = false;
            return item;
          });
          this.total = res.data.data.total;
          this.loading = false;
          this.listLoading = false;
        })
        .catch(() => {
          this.loading = false;
          this.listLoading = false;
        });
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
        .filter((item) => item.totalScore)
        .map((item) => item.uuid);
    },
    getSheetList() {
      this.pageIndex = 1;
      this.exampapersheetPage();
    },
  },
};
</script>
<style lang="scss" scoped>
.sheetList {
  label {
    margin-bottom: 0;
  }
  .el-table {
    ::v-deep .cell {
      padding-left: 5px;
      padding-right: 5px;
      .el-button + .el-button {
        margin-left: 5px;
      }
    }
  }
  .self-amending-form {
    ::v-deep .el-dialog__body {
      padding-bottom: 24px;
    }
  }
  .upload-file {
    margin-bottom: 24px;
    ::v-deep .el-upload {
      display: block;

      .el-upload-dragger {
        width: 100%;
      }
    }
    ::v-deep .el-upload--text {
      line-height: 180px;
    }
  }
  .item-list {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    ::v-deep .el-input__icon {
      line-height: 32px;
    }
    > span {
      flex-shrink: 0;
    }
  }
  .el-pagination {
    display: flex;
    justify-content: flex-end;
    margin-top: 24px;
  }
  .sheet-list {
    background-color: #ffffff;
    padding: 18px;
    .btn-list {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 18px;
    }
  }
  .succ {
    color: $success-color;
  }
  .err {
    color: #d92020;
  }
  .search-box {
    margin: 24px 0;
    background: #ffffff;
    padding: 33px 24px 0;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    line-height: 32px;
    flex-wrap: wrap;
    .search-left {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
    }
    .el-input,
    .el-date-editor,
    .el-select,
    .el-button {
      margin-bottom: 24px;
    }
    .el-date-editor {
      line-height: 32px;
      margin-right: 24px;
    }

    .el-select {
      width: 100px;
      margin-right: 24px;
    }

    .el-input {
      width: 160px;
      margin-right: 24px;
    }
  }
}
</style>
