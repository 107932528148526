<template>
  <div class="cancelSheet">
    <el-dialog title="复制答题卡" :visible.sync="dialogVisible" width="413px">
      <div>复制答题卡, 是否继续?</div>
      <el-checkbox
        v-if="$parent.userInfo.schoolVersion != 1"
        v-model="ifCopy"
        style="margin-top: 8px"
      >
        复制试题
      </el-checkbox>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" :loading="btnLoading" @click="submit()">
          确 定
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { exampapersheetDuplicate } from "@/core/api/exam/sheet";
export default {
  name: "CancelSheet",
  data() {
    return {
      dialogVisible: false,
      btnLoading: false,
      ifCopy: false,
      indexSubject: {},
    };
  },
  created() {},
  methods: {
    submit() {
      let data = {
        sheetUuid: this.indexObj.uuid,
        ifCopy: this.ifCopy,
      };
      exampapersheetDuplicate(data)
        .then(() => {
          this.$message({
            showClose: true,
            message: "复制成功!",
            type: "success",
          });
          this.btnLoading = false;
          this.dialogVisible = false;
          this.$parent.exampapersheetPage();
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
    init(indexObj) {
      this.ifCopy = false;
      this.dialogVisible = true;
      this.indexObj = JSON.parse(JSON.stringify(indexObj));
    },
  },
};
</script>
<style lang="scss" scoped>
.cancelSheet {
}
</style>
